import seon from '@seontechnologies/seon-javascript-sdk'
import errorService from '@/services/errorService'

let isSeonLoaded = false
const { VUE_APP_SEON_CDN_HOST, VUE_APP_IS_LOCAL } = process.env
const seonConfig = {
  dnsResolverDomain: VUE_APP_SEON_CDN_HOST,
  silentMode: !VUE_APP_IS_LOCAL,
}

// setter should only be used for unit testing.
// local variable should only be modified via seon service or unit tests
export function setIsSeonLoaded(newVal) {
  isSeonLoaded = newVal
}

export function loadSeon() {
  if (!isSeonLoaded) {
    try {
      seon.init()
      isSeonLoaded = true
    } catch (err) {
      errorService.handle(`SEON library failed to load: ${err}`)
    }
  }
}

export function getSession() {
  return seon.getSession(seonConfig).catch((err) => {
    errorService.handle(`Unable to generate SEON session: ${err}`)
    return undefined
  })
}
